// material-ui
import { useMutation, useQuery } from "@apollo/client";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Skeleton, Typography } from "@mui/material";
import { PlatformCodeEnum } from "__generated__/graphql";
import ErrorDialog from "components/Dialog/ErrorDialog";
import { useToggle } from "hooks/useToggle";
import { useState } from "react";
import * as service from "services/productService";

interface Prop {
  readonly platformCode: PlatformCodeEnum;
  readonly imageName: string;
}

type TProduct = {
  gameId: string;
  id: string;
  order: number;
  type?: string;
  name?: string;
  picturePath?: string;
  platformCode: PlatformCodeEnum;
};

type TGetProduct = {
  gameInfos: {
    data: Array<TProduct>;
  };
};

const ProductListChildren = ({ platformCode, imageName }: Prop) => {
  const [launchingGame, setLaunchingGame] = useState("");
  const [isError, toggleError, setIsError] = useToggle(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onError = (message: string) => {
    setIsError(true);
    setErrorMessage(message);
  };

  const { loading: isLoadingData, data: products } = useQuery<TGetProduct, any>(
    service.getAllProducts,
    {
      context: { fetchOptions: { method: "GET" } },
      onError: (data) => {
        onError(data?.message);
      },
      variables: {
        input: {
          platformCode,
        },
      },
    }
  );

  const [launchGame, { loading: isLaunchGame }] = useMutation(
    service.launchGame,
    {
      context: { fetchOptions: { method: "GET" } },
      onCompleted: (data) => {
        if (data?.launchGame?.url) {
          window.open(data.launchGame?.url, "_blank");
        }
      },
      onError: (data) => {
        onError(data?.message);
      },
    }
  );

  return (
    <>
      <Box
        component="div"
        className="icgg-product"
        sx={{ paddingLeft: { lg: "50px" } }}
      >
        <ErrorDialog
          open={isError}
          message={errorMessage}
          handleClose={toggleError}
        />
        <Box
          component="div"
          className="grid-container"
          sx={{
            width: { xs: "100vw", lg: "80vw" },
            justifyContent: { xs: "center", lg: "start" },
          }}
        >
          {!isLoadingData &&
            products?.gameInfos?.data?.map((p) => (
              <Box component="div" className="grid-box-wrapper" key={p.id}>
                <Box component="div" className="grid-box">
                  <Box
                    component="img"
                    className="gr-half-background"
                    alt={p.name + " background"}
                    src={p.picturePath}
                  />

                  <Box component="div" className="gr-game-logo">
                    <Box
                      component="img"
                      className="img-game-logo"
                      alt={p.name}
                      src={p.picturePath}
                    />
                    <Typography
                      className="gr-game-title"
                      sx={{
                        fontSize: { xs: "10px", sm: "18px", md: "28px" },
                        lineHeight: { lg: "34px" },
                        marginTop: { xs: "0", sm: "4%" },
                      }}
                    >
                      {p.name}
                    </Typography>
                  </Box>

                  <Box
                    component="div"
                    className="gr-slot"
                    sx={{
                      width: { xs: "52px", sm: "100px", md: "124px" },
                      height: { xs: "16px", sm: "32px", md: "40px" },
                      lineHeight: { xs: "16px", sm: "32px", md: "40px" },
                      fontSize: {
                        xs: "8px",
                        sm: "14px",
                        md: "20px",
                      },
                    }}
                  >
                    {p.type}
                  </Box>

                  <Box component="div" className="gr-bottom">
                    <Box
                      component="img"
                      className="img-logo"
                      alt="GameConverge"
                      src={`assets/images/${imageName}.png`}
                    />
                    <LoadingButton
                      className="btn-try-now"
                      loading={launchingGame === p.id && isLaunchGame}
                      loadingPosition="start"
                      sx={{
                        height: { xs: "16px", sm: "32px", md: "40px" },
                        lineHeight: { xs: "16px", sm: "40px" },
                        width: { xs: "52px", sm: "100px", md: "124px" },
                        borderRadius: { xs: "3px", md: "7px" },
                        fontSize: {
                          xs: "8px",
                          sm: "14px",
                          md: "20px",
                        },
                      }}
                      onClick={() => {
                        setLaunchingGame(p.id);
                        launchGame({
                          variables: {
                            input: {
                              gameId: p.gameId,
                              platformCode: p.platformCode,
                            },
                          },
                        });
                      }}
                    >
                      Try Now
                    </LoadingButton>
                  </Box>
                </Box>
              </Box>
            ))}

          {isLoadingData && (
            <>
              <Skeleton variant="rectangular" className="grid-box" />
              <Skeleton variant="rectangular" className="grid-box" />
              <Skeleton variant="rectangular" className="grid-box" />
              <Skeleton variant="rectangular" className="grid-box" />
              <Skeleton variant="rectangular" className="grid-box" />
              <Skeleton variant="rectangular" className="grid-box" />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ProductListChildren;
