export enum ProductType {
  ALL = 0,
  GC = 1,
  GCFB = 2,
  PG = 3,
  JDB = 4,
  PLAY8 = 5,
  SEXYBCRT = 6,
  PP = 7,
  FC = 8,
}

type TMenuTab = Array<{
  id: ProductType;
  path: string;
  title: string;
}>;

export const menuTabs: TMenuTab = [
  {
    id: ProductType.ALL,
    path: '/products',
    title: "Show All",
  },
  {
    id: ProductType.GC,
    path: '/products-gc-gaming',
    title: "GC",
  },
  {
    id: ProductType.GCFB,
    path: '/products-gcfb-gaming',
    title: "GCFB",
  },
  {
    id: ProductType.PG,
    path: '/products-pg-gaming',
    title: "PG",
  },
  {
    id: ProductType.JDB,
    path: '/products-jdb-gaming',
    title: "JDB",
  },
  {
    id: ProductType.PLAY8,
    path: '/products-play8-gaming',
    title: "PLAY8",
  },
  {
    id: ProductType.SEXYBCRT,
    path: '/products-sexybcrt-gaming',
    title: "SEXYBCRT",
  },
  {
    id: ProductType.PP,
    path: '/products-pp-gaming',
    title: "PP",
  },
  {
    id: ProductType.FC,
    path: '',
    title: "FC",
  },
];