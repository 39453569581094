import SEO from "components/SEO";
import ProductList from "components/ProductList/ProductList";
import ProductListChildren from "components/ProductList/ProductListChildren";
import { PlatformCodeEnum } from "__generated__/graphql";

const ProductJDBGaming = () => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "GameConverge",
    url: `${window.location.origin}/products-jdb-gaming`,
    description:
      "Diverse online entertainment solutions. Provide you with the most exciting and memorable gaming experience!",
    image: `${window.location.origin}/assets/gameConverge_logo.png`,
  };

  return (
    <>
      <SEO
        title="Product | GameConverge"
        description="Diverse online entertainment solutions. Provide you with the most exciting and memorable gaming experience!"
        name="GameConverge | Empowering Game Operators with Premier API"
        type="website"
        ogTitle="Products & Providers | JDB"
        ogUrl={`${window.location.origin}/products-jdb-gaming`}
        ogImage={`${window.location.origin}/assets/images/og_image_20230926.jpg`}
        schema={schema}
      ></SEO>

      <ProductList
        title="JDB"
        introduceText="Diverse online entertainment solutions, fish shooting games, slot games, card games, and bingos to various needs and provide you with the most exciting and memorable gaming experience!"
        imageName="JDB_logo_rainbow"
        bgImageName="kingkong_bg"
      >
        <ProductListChildren 
          platformCode={PlatformCodeEnum.JdbSwA}
          imageName="JDB_logo_rainbow"
        />
      </ProductList>
    </>
  );
};

export default ProductJDBGaming;
