// material-ui
import { Box, Grid, Link, Typography } from "@mui/material";
import "./Footer.scss";

const Footer = () => {
  return (
    <>
      <Grid className="main-footer" container>
        <Box component="div" className="footer-top-content">
          <Box
            component="img"
            alt="Game Coverage"
            src="assets/images/logo_large.png"
            sx={{
              width: {
                xs: "102px",
                md: "unset"
              },
              paddingBottom: {
                xs: "0",
                md: "32px"
              }
            }}
          />
          <Box component="div" className="footer-content-top-right" >
            <Box component="div" className="footer-box-content">
              <Box
                component="img"
                alt="email gameConverge"
                src="assets/images/icon_email.png"
                sx={{
                  width: {
                    xs: "18px",
                    md: "unset"
                  }
                }}
              />
              <Typography sx={{fontSize: {xs: "14px !important", md: "20px !important"}}}>
                <Link href="mailto:https://business@gameconverge.org" target="_blank">
                  business@gameconverge.org
                </Link>
              </Typography>
            </Box>

            <Box component="div" className="footer-box-content">
              <Box
                component="img"
                alt="telegram GameConverge"
                src="assets/images/icon_plane.png"
                sx={{
                  width: {
                    xs: "18px",
                    md: "unset"
                  }
                }}
              />
              <Typography sx={{fontSize: {xs: "14px !important", md: "20px !important"}}}>
                <Link href="https://t.me/GameConverge" target="_blank">
                  https://t.me/GameConverge
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box component="footer" className="footer-bottom-content" sx={{fontSize: {xs: "14px !important", md: "20px !important"}}}>
          <span className="footer-text">
            ©2023
            <span className="footer-game-coverage">GameConverge</span>. All
            Rights Reserved.
          </span>
        </Box>
      </Grid>
    </>
  );
};

export default Footer;
