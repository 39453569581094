import { ReactNode, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import { ProductType, menuTabs } from "components/menu";
import ComingSoonComponent from "components/ComingSoon/ComingSoon";
import Footer from "components/Footer/Footer";
import "./ProductList.scss";

interface Prop {
  readonly children: ReactNode,
  readonly title: string;
  readonly introduceText: string;
  readonly imageName: string;
  readonly bgImageName: string;
}

const ProductList = ({ 
  children,
  title,
  introduceText,
  imageName,
  bgImageName
}: Prop) => {
  const curPathName = useLocation().pathname;
  const [isOpenComingSoon, setComingSoonStatus] = useState(false);
  let comingSoonTimeOut: any;

  const FC_Navigation = () => {
    if (comingSoonTimeOut) clearTimeout(comingSoonTimeOut);
    setComingSoonStatus(true);

    comingSoonTimeOut = setTimeout(() => {
      setComingSoonStatus(false);
    }, 100);
  }

  const contentRightImgWidth = (title: string) => {
    switch (title) {
      case 'GC':
        return {
          xs: "75px",
          lg: "200px"
        }
    
      default:
        return {
          xs: "100px",
          lg: "350px"
        }
    }
  }

  return (
    <>
      <Grid
        className="product-icg-list-gaming"
        container
        sx={{
          paddingLeft: { xl: "235px", lg: "150px", xs: "16px" },
          paddingRight: { xl: "235px", lg: "150px", xs: "16px" },
          position: "relative",
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "start",
            flexDirection: "column",
            paddingTop: {
              xs: "100px",
              lg: "162px",
            },
            zIndex: 10,
            paddingLeft: { xl: "80px", lg: "50px", xs: "16px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              paddingLeft: { xs: "20px", sm: "70px" },
              borderLeft: "1px solid #FF8E6E",
              position: { xs: "relative", lg: "unset" },
            }}
          >
            <Box component="div" className="content-left" sx={{}}>
              <Typography
                component="h1"
                className="title"
                sx={{
                  fontSize: { lg: "80px", xs: "32px" },
                  marginBottom: {
                    xs: "20px",
                    lg: "38px",
                  },
                }}
              >
                {title}
              </Typography>
              <Typography
                className="header-1"
                sx={{
                  fontSize: { xs: "14px", sm: "20px" },
                  width: "450px",
                  maxWidth: {
                    lg: "calc(100vw - 64px)",
                    xs: "calc(50vw - 10px)",
                  },
                  paddingTop: {
                    xs: "0",
                    lg: "25px",
                  },
                }}
              >
                {introduceText}
              </Typography>

              {/* ====================================================== Menu ===================================== */}
              <Box
                component="div"
                className="menu-wrapper"
                sx={{
                  overflowX: {
                    xs: "auto",
                    md: "visible",
                  },
                }}
              >
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                  }}
                >
                  {menuTabs.map((item) => {
                    if (item.id === ProductType.FC) return (
                      <Button
                        key={item.path}
                        className={`btn ${
                          curPathName === item.path && "active"
                        }`}
                        onClick={() => FC_Navigation()}
                      >
                        {item.title}
                      </Button>
                    )
                    else return (
                      <Link key={item.id} to={item.path}>
                        <Button
                          className={`btn ${
                            curPathName === item.path && "active"
                          }`}
                        >
                          {item.title}
                        </Button>
                      </Link>
                    )
                  })}
                </Box>
              </Box>
            </Box>

            <Box component="div" className="content-right">
              <Box
                component="img"
                sx={{
                  zIndex: 0,
                  position: "absolute",
                  right: {
                    xs: "2%",
                    lg: "18%",
                  },
                  top: {
                    xs: "25%",
                    lg: "unset",
                  },
                  // 圖片目前分兩種比例 - 正方、長方
                  width: contentRightImgWidth(title),
                }}
                alt="Game Coverage Logo"
                src={`assets/images/${imageName}.png`}
              />
            </Box>
          </Box>
        </Grid>

        {/* page content */}
        {children}
        <Box
          component="img"
          className="bg-img"
          sx={{
            maxWidth: {
              xs: "100vw",
              lg: "calc(100vw - 16px)",
            },
          }}
          alt="background image game coverage"
          src={`assets/images/${bgImageName}.png`}
        />
        <ComingSoonComponent
          trigger={isOpenComingSoon}
        />
      </Grid>

      <Footer />
    </>
  );
};

export default ProductList;
