import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useEffect, useState } from "react";
import "./ErrorDialog.scss";

type TErrorDialog = {
  open: boolean;
  message?: string;
  handleClose: () => void;
};

export default function ErrorDialog({
  open,
  message = "An error has occurred.",
  handleClose,
}: TErrorDialog) {
  const dialogRef = document.querySelector<HTMLElement>("._dialog_");
  const [isElementReady, setIsElementReady] = useState(false);

  useEffect(() => {
    if (open) {
      setIsElementReady(false);
      setTimeout(() => {
        setIsElementReady(true);
      }, 10);
    }
  }, [message, open]);

  const onDialogClose = () => {
    setIsElementReady(false);
    handleClose();
  };

  return (
    <Dialog
      className="_dialog_"
      open={open}
      onClose={onDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionProps={{ timeout: 0 }}
      sx={{
        top: `calc(50vh - ${(dialogRef?.offsetHeight ?? 141) / 2}px)`,
        left: `calc(50vw - ${(dialogRef?.offsetWidth ?? 273) / 2}px)`,
        visibility: `${isElementReady ? "visible" : "hidden"}`,
        maxWidth: {
            xs: "80vw",
            md: "600px"
        }
      }}
    >
      <Box
        component="img"
        className="icon-close"
        alt="icon warning"
        src="assets/images/icon/icon_x.png"
        onClick={handleClose}
      ></Box>

      <Box
        component="img"
        className="icon-warning"
        alt="icon warning"
        src="assets/images/icon/icon_warning.png"
      ></Box>
      <Box component="div" className="dialog-body">
        {message}
      </Box>
    </Dialog>
  );
}
