import { ThemeProvider } from "@mui/material";
import "./App.css";
import Routes from "./routes";
import themes from "themes";
import { useSelector } from "react-redux";

import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  loadDevMessages();
  loadErrorMessages();
}

function App() {
  const customization = useSelector((state: any) => state.customization);
  return (
    <ThemeProvider theme={themes(customization)}>
      <Routes />
    </ThemeProvider>
  );
}

export default App;
