import { lazy } from 'react';
import MainLayout from '../containers/MainLayout/MainLayout';
import { RouteObject } from 'react-router-dom';
import Loadable from 'components/Loadable/Loadable';
import ProductPGGaming from 'containers/ProductICG/ProductPGGaming';
import ProductJDBGaming from 'containers/ProductICG/ProductJDBGaming';
import ProductPLAY8Gaming from 'containers/ProductICG/ProductPLAY8Gaming';
import ProductSEXYBCRTGaming from 'containers/ProductICG/ProductSEXYBCRTGaming';
import ProductPPGaming from 'containers/ProductICG/ProductPPGaming';

// project imports
const HomePage = Loadable(lazy(() => import('containers/HomePage/HomePage')));
const Product = Loadable(lazy(() => import('containers/Product/Product')));
const ProductGCGaming = Loadable(lazy(() => import('containers/ProductICG/ProductGCGaming')));
const ProductGCFBGaming = Loadable(lazy(() => import('containers/ProductICG/ProductGCFBGaming')));
const Page404 = Loadable(lazy(() => import('components/Error/Page404')));


export const Path = {
  HomePage: '/',
  Product: '/products',
  ProductGC: '/products-gc-gaming',
  ProductGCFB: '/products-gcfb-gaming',
  ProductPG: '/products-pg-gaming',
  ProductJDB: '/products-jdb-gaming',
  ProductPLAY8: '/products-play8-gaming',
  ProductSEXYBCRT: '/products-sexybcrt-gaming',
  ProductPP: '/products-pp-gaming',
  ProductFC: '',
}

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes: RouteObject = {
  path: '',
  element: <MainLayout />,
  children: [
    {
      path: Path.HomePage,
      element: <HomePage />
    },
    {
      path: Path.Product,
      element: <Product />
    },
    {
      path: Path.ProductGC,
      element: <ProductGCGaming />
    },
    {
      path: Path.ProductGCFB,
      element: <ProductGCFBGaming />
    },
    {
      path: Path.ProductPG,
      element: <ProductPGGaming />
    },
    {
      path: Path.ProductJDB,
      element: <ProductJDBGaming />
    },
    {
      path: Path.ProductPLAY8,
      element: <ProductPLAY8Gaming />
    },
    {
      path: Path.ProductSEXYBCRT,
      element: <ProductSEXYBCRTGaming />
    },
    {
      path: Path.ProductPP,
      element: <ProductPPGaming />
    },
    {
      path: '*',
      element: <Page404 />
    },
  ]
};

export default MainRoutes;
