import SEO from "components/SEO";
import ProductList from "components/ProductList/ProductList";
import ProductListChildren from "components/ProductList/ProductListChildren";
import { PlatformCodeEnum } from "__generated__/graphql";

const ProductPLAY8Gaming = () => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "GameConverge",
    url: `${window.location.origin}/products-play8-gaming`,
    description:
      "Play8 offers a wide range of styles of fishing shooting games, slot games that are sure to hit your preferred choice.",
    image: `${window.location.origin}/assets/gameConverge_logo.png`,
  };

  return (
    <>
      <SEO
        title="Product | GameConverge"
        description="Play8 offers a wide range of styles of fishing shooting games, slot games that are sure to hit your preferred choice."
        name="GameConverge | Empowering Game Operators with Premier API"
        type="website"
        ogTitle="Products & Providers | PLAY8"
        ogUrl={`${window.location.origin}/products-play8-gaming`}
        ogImage={`${window.location.origin}/assets/images/og_image_20230926.jpg`}
        schema={schema}
      ></SEO>

      <ProductList
        title="PLAY8"
        introduceText="Play8 offers a wide range of styles of fishing shooting games, slot games that are sure to hit your preferred choice."
        imageName="play8_logo"
        bgImageName="play8_bg"
      >
        <ProductListChildren 
          platformCode={PlatformCodeEnum.Play8SwA}
          imageName="play8_logo"
        />
      </ProductList>
    </>
  );
};

export default ProductPLAY8Gaming;
