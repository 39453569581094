import { Helmet } from "react-helmet-async";

type TSEO = {
  title?: string;
  description?: string;
  name?: string;
  type?: string;
  ogTitle?: string;
  ogUrl?: string;
  ogImage?: string;
  schema?: Record<string, string>
};
const SEO = ({
  title,
  description,
  name,
  type,
  ogTitle,
  ogUrl,
  ogImage,
  schema
}: TSEO) => {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      {/* Facebook tags */}
      <meta property="og:title" content={ogTitle} />
      <meta property="og:type" content={type} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={name} />
      {/* Links */}
      <link rel="canonical" href={ogUrl} />
      {/* Scripts */}
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  );
};
export default SEO;
