import SEO from "components/SEO";
import ProductList from "components/ProductList/ProductList";
import ProductListChildren from "components/ProductList/ProductListChildren";
import { PlatformCodeEnum } from "__generated__/graphql";

const ProductPGGaming = () => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "GameConverge",
    url: `${window.location.origin}/products-pg-gaming`,
    description:
      "As a game changer in the mobile gaming industry, PG has made a massive impact on the gaming world since its inception in 2015.",
    image: `${window.location.origin}/assets/gameConverge_logo.png`,
  };

  return (
    <>
      <SEO
        title="Product | GameConverge"
        description="As a game changer in the mobile gaming industry, PG has made a massive impact on the gaming world since its inception in 2015."
        name="GameConverge | Empowering Game Operators with Premier API"
        type="website"
        ogTitle="Products & Providers | PG"
        ogUrl={`${window.location.origin}/products-pg-gaming`}
        ogImage={`${window.location.origin}/assets/images/og_image_20230926.jpg`}
        schema={schema}
      ></SEO>

      <ProductList
        title="PG"
        introduceText="As a game changer in the mobile gaming industry, PG SOFT™ has made a massive impact on the gaming world since its inception in 2015."
        imageName="PGSOFT_logo"
        bgImageName="pg_bg"
      >
        <ProductListChildren 
          platformCode={PlatformCodeEnum.PgSwA}
          imageName="PGSOFT_logo"
        />
      </ProductList>
    </>
  );
};

export default ProductPGGaming;
