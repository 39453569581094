import SEO from "components/SEO";
import ProductList from "components/ProductList/ProductList";
import ProductListChildren from "components/ProductList/ProductListChildren";
import { PlatformCodeEnum } from "__generated__/graphql";

const ProductSEXYBCRTGaming = () => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "GameConverge",
    url: `${window.location.origin}/products-sexybcrt-gaming`,
    description:
      "Sexybcrt is an online game and can be played by any individual with an internet connection. Come with aesthetically pleasing graphics making the game more enticing each round.",
    image: `${window.location.origin}/assets/gameConverge_logo.png`,
  };

  return (
    <>
      <SEO
        title="Product | GameConverge"
        description="Sexybcrt is an online game and can be played by any individual with an internet connection. Come with aesthetically pleasing graphics making the game more enticing each round."
        name="GameConverge | Empowering Game Operators with Premier API"
        type="website"
        ogTitle="Products & Providers | SEXYBCRT"
        ogUrl={`${window.location.origin}/products-sexybcrt-gaming`}
        ogImage={`${window.location.origin}/assets/images/og_image_20230926.jpg`}
        schema={schema}
      ></SEO>

      <ProductList
        title="SEXYBCRT"
        introduceText="Sexybcrt is an online game and can be played by any individual with an internet connection. Come with aesthetically pleasing graphics making the game more enticing each round."
        imageName="Sexy_Logo"
        bgImageName="Sexy_BG"
      >
        <ProductListChildren 
          platformCode={PlatformCodeEnum.SexybcrtSwA}
          imageName="Sexy_Logo"
        />
      </ProductList>
    </>
  );
};

export default ProductSEXYBCRTGaming;
