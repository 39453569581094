import { Box, Fade } from "@mui/material";
import { memo } from "react";

type TComingSoon = {
  left?: string;
  top?: string;
  trigger?: any;
};

const _width = 137,
  _height = 120;

const ComingSoonComponent = ({
  trigger,
}: TComingSoon) => {
  return (
    <Fade in={trigger} timeout={trigger ? 0: 3000}>
      <Box
        component="img"
        className="img-coming-soon"
        alt="coming soon"
        src="assets/images/coming_soon.png"
        sx={{
          zIndex: 100,
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          position: "fixed",
          width: `${_width}px`,
          height: `${_height}px`,
        }}
      />
    </Fade>
  );
};
export default memo(ComingSoonComponent);
