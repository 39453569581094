import SEO from "components/SEO";
import ProductList from "components/ProductList/ProductList";
import ProductListChildren from "components/ProductList/ProductListChildren";
import { PlatformCodeEnum } from "__generated__/graphql";

const ProductPPGaming = () => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "GameConverge",
    url: `${window.location.origin}/products-pp-gaming`,
    description:
      "Pragmatic Play is a leading game developer providing player-favourites to the most successful global brands in the iGaming industry.",
    image: `${window.location.origin}/assets/gameConverge_logo.png`,
  };

  return (
    <>
      <SEO
        title="Product | GameConverge"
        description="Pragmatic Play is a leading game developer providing player-favourites to the most successful global brands in the iGaming industry."
        name="GameConverge | Empowering Game Operators with Premier API"
        type="website"
        ogTitle="Products & Providers | PP"
        ogUrl={`${window.location.origin}/products-pp-gaming`}
        ogImage={`${window.location.origin}/assets/images/og_image_20230926.jpg`}
        schema={schema}
      ></SEO>

      <ProductList
        title="PP"
        introduceText="Pragmatic Play is a leading game developer providing player-favourites to the most successful global brands in the iGaming industry. Offer a multi-product portfolio of slots, live casino, bingo and more, available in all major regulated markets."
        imageName="PP_Logo_On-Black"
        bgImageName="PP_BG"
      >
        <ProductListChildren 
          platformCode={PlatformCodeEnum.PpSwA}
          imageName="PP_Logo_On-Black"
        />
      </ProductList>
    </>
  );
};

export default ProductPPGaming;
