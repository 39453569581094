import { gql } from "__generated__";

  export const launchGame = gql(`
  mutation launchGame($input: LaunchGameInput!) {
    launchGame(input: $input) {
      success
      url
    }
  }
  `);

  export const getAllProducts = gql(`
  query H($input: GameInfoInput) {
    gameInfos(input: $input, first: 50, page: 1) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        platformCode
        type
        name
        gameId
        picturePath
        order
      }
    }
  }`)
